import * as React from "react";
import { css } from "@emotion/core";
import GoogleMapReact from "google-map-react";
// import MarkerClusterer from '@googlemaps/markerclustererplus';
import theme from '../theme'

const mapStyle = css`
  max-width: 900px;
  margin: 0 auto;
  height: 500px;
`;

const ensureProtocol = url => {
  const pattern = /^((http|https):\/\/)/;
  if(!pattern.test(url)) {
    return "https://" + url;
  }
  return url
}


const getMarkerIcon = (color) => `
<svg width="35px" height="48px" viewBox="0 0 35 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g fill-rule="nonzero">
          <path d="M34.688,17.344 C34.688,26.923 17.344,47.688 17.344,47.688 C17.344,47.688 0,26.923 0,17.344 C0,7.7651733 7.7651733,0 17.344,0 C26.9228267,0 34.688,7.7651733 34.688,17.344 L34.688,17.344 Z" id="Path_3" fill="#1A1818"></path>
          <circle fill="${color}" cx="17.343" cy="17.393" r="14.026"></circle>
      </g>
  </g>
</svg>
`

const Map = ({ places, clickedElement }) => {
  const [markers, setMarkers] = React.useState(null);
  // const [cluster, setCluser] = React.useState(null);
  const infoWindowRef = React.useRef(null);

  const handleApiLoaded = (map) => {
    if (!window.google) {
      return;
    }
    // if (cluster) {
      // cluster.setMap(null)
      // setCluser(null)
    // }
    if (markers) {
      markers.forEach((marker) => marker.setMap(null));
      setMarkers(null);
    }
    
    if (!infoWindowRef.current) {
      infoWindowRef.current = new window.google.maps.InfoWindow();
    }
    const _markers = [];
    places.forEach(({ frontmatter, html, id }) => {
      const _marker = new window.google.maps.Marker({
        id,
        position: {
          lat: frontmatter.position_y,
          lng: frontmatter.position_x,
        },
        map,
        title: frontmatter.title,
        icon: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(getMarkerIcon(theme.colors.categories[frontmatter.type])),
      });
      _marker.addListener("click", () => {
        infoWindowRef.current.close();
        infoWindowRef.current.setContent(`
          <h3>${frontmatter.title}</h3>
          <p>${frontmatter.address}</p>
          ${frontmatter.phone ? '<p>' + frontmatter.phone + '</p>' : ''}
          ${frontmatter.website ? '<p><a href="' + ensureProtocol(frontmatter.website) + '" target="_blank" rel="noreferrer noopener">' + frontmatter.website + '</p>' : ''}
          ${frontmatter.email ? '<p>' + frontmatter.email + '</p>' : ''}
          <p>${html}</p>
        `);
        infoWindowRef.current.open(_marker.getMap(), _marker);
      });
      _markers.push(_marker);
    });
    setMarkers(_markers);
    // const markerCluster = new MarkerClusterer(map, _markers, {
    //   imagePath:
    //   "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    // });
    // setCluser(markerCluster)
  };

  const mapRef = React.useRef(null);
  
  React.useEffect(() => {
    handleApiLoaded(mapRef.current);
  }, [places]);

  React.useEffect(() => {
    if (!clickedElement || !markers) {
      return
    }
    window.google.maps.event.trigger(markers.find(_marker => _marker.id === clickedElement), 'click')
  }, [clickedElement, markers])

  return (
    <div css={mapStyle}>
      <GoogleMapReact
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = map;
          handleApiLoaded(map, maps);
        }}
        defaultZoom={15}
        yesIWantToUseGoogleMapApiInternals
        defaultCenter={{
          lat: 51.1121619,
          lng: 17.0586091,
        }}
        options={{
          mapId: "7874f10971c7fe39",
        }}
        bootstrapURLKeys={{ key: "AIzaSyA0fcnMxuEEIdyxOxj6ir4e4kbQ1GD9KZk" }}
      ></GoogleMapReact>
    </div>
  );
};

export default Map;
