import * as React from "react";
import { css } from "@emotion/core";

import { useStaticQuery, graphql, Link } from "gatsby";

import ArticleComponent from "../components/ArticleComponent";
import TextWrapper from "../components/TextWrapper";
import Map from "../components/Map";

const indexContainerStyle = (theme) => css`
  columns: 3 auto;

  & > * {
    break-inside: avoid-column;
    page-break-inside: avoid;
  }

  ${theme.breakpoints.tablet} {
    columns: 2 auto;
  }
  ${theme.breakpoints.mobile} {
    columns: 1 auto;
  }
`;

const indexListStyle = css`
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
`;

const listHeader = css`
  font-size: 1.2rem;
`;

const indexListButtonStyle = css`
  appearance: none;
  border: 0;
  background-color: transparent;
  font-size: inherit;
  padding: 0;
  cursor: pointer;
  text-align: left;
`;

const categoryButtonStyle = (active) => (theme) => css`
  appearance: none;
  border: 0;
  background-color: transparent;
  border: 2px solid ${theme.colors.black};
  border-radius: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 5px 20px;
  cursor: pointer;
  transition: box-shadow 0.2s;
  box-shadow: ${active ? "0 3px 4px rgba(0, 0, 0, 0.4)" : "none"};
`;

const buttonContainerStyle = (theme) => css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
  margin-bottom: 30px;

  ${theme.breakpoints.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${theme.breakpoints.mobile} {
    grid-template-columns: 1fr;
  }
`;

const categoryBadgeStyle = (category) => (theme) => css`
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${category
    ? theme.colors.categories[category]
    : "transparent"};
`;

const Mapa = () => {
  const [category, setCategory] = React.useState(null);
  const [clickedElement, setClickedElement] = React.useState(null);
  const { places } = useStaticQuery(
    graphql`
      query {
        places: allMarkdownRemark(
          filter: { frontmatter: { layout: { eq: "place" } } }
        ) {
          nodes {
            id
            html
            fields {
              slug
            }
            frontmatter {
              title
              address
              phone
              website
              email
              position_x
              position_y
              type
            }
          }
        }
      }
    `
  );

  const aggregatedPlaces = React.useMemo(() => {
    return places.nodes.reduce((acc, current) => {
      const type = current.frontmatter.type;
      return {
        ...acc,
        [type]: [...(acc[type] || []), current],
      };
    }, {});
  }, [places]);

  const filteredAggregatedPlaces = React.useMemo(() => {
    return category
      ? { [category]: aggregatedPlaces[category] }
      : aggregatedPlaces;
  }, [aggregatedPlaces, category]);

  return (
    <ArticleComponent title="Mapa">
      <TextWrapper>
        <article>
          <h1>Mapa handlowo-usługowa Osiedla Plac Grunwaldzki we Wrocławiu</h1>
          <p>
            <strong>
              Niniejsza mapa powstała w ramach realizowanego przez Fundację
              Ładne Historie projektu „Plac Grunwaldzki OD NOWA - mapowanie
              najbliższego otoczenia”. Projekt ten został zrealizowany dzięki
              dofinansowaniu ze środków Urzędu Miasta Wrocławia.
            </strong>
          </p>
          <p>
            Wszystkie drogi prowadzą na Plac Grunwaldzki – osiedle kojarzone
            przede wszystkim z niesamowitą architekturą, studentami i
            studentkami oraz symbolicznym dla Wrocławia Mostem Grunwaldzkim.
            Jednak historia tego miejsca także wiąże się z handlem. Na
            przestrzeni kilku dekad znajdowały się tutaj szaberplac, namiot
            „Goliat” i targowisko, które w pamięci mieszkanek i mieszkańców
            osiedla zapisało się jako miejsce rozmów, rozwijania sąsiedzkich
            przyjaźni i pielęgnowania osiedlowych relacji. Nic więc dziwnego, że
            trzydziesta rocznica otwarcia namiotu „Goliat” zainspirowała nas do
            przyjrzenia się lokalnym punktom handlowo-usługowym i zebrania ich w
            jedną, przyjazną mieszkańcom i mieszkankom Grunwaldu mapę.
          </p>
          <p>
            Wszystkie punkty podzieliliśmy na kilka wymienionych poniżej
            kategorii, a każda z nich ma przypisany do siebie kolor. Korzystanie
            z mapy jest bardzo proste – swoje poszukiwania punktów możecie
            rozpocząć od wyboru kategorii, która Was interesuje. Po jej
            kliknięciu na mapie zobaczycie znaczniki, które wskazują lokalizację
            wszystkich podmiotów objętych daną kategorią (na przykład: po
            kliknięciu kategorii „WARZYWNIAKI” na mapie pojawią się sklepy
            warzywno-owocowe oznaczone kolorem zielonym). Dodatkowo po
            kliknięciu kolorowego znacznika na mapie pojawi się okienko z danymi
            kontaktowymi danego lokalu. Z mapy możecie także korzystać
            wybierając nazwę danego punktu z listy pod mapą – po kliknięciu na
            nią na mapie pojawi się znacznik wskazujący lokalizację danego
            punktu.
          </p>
          <p>
            Mamy nadzieję, że nasza mapa będzie cały czas rosnąć i
            systematycznie będą pojawiać się na niej kolejne miejsca! Jeśli
            właśnie otwierasz nowy sklep lub punkt usługowy na Placu
            Grunwaldzkim (lub zauważyłeś/łaś, że prowadzone przez Ciebie miejsce
            nie jest oznaczone na naszej mapie) – zgłoś się do nas wypełniając{" "}
            <Link to="/formularz">formularz</Link>.
          </p>
          <div css={buttonContainerStyle}>
            {Object.keys(aggregatedPlaces).map((_category) => (
              <button
                key={_category}
                css={categoryButtonStyle(_category === category)}
                onClick={() => setCategory(_category)}
              >
                <span css={categoryBadgeStyle(_category)}></span>
                {_category}
              </button>
            ))}
            <button
              css={categoryButtonStyle(!category)}
              onClick={() => setCategory(null)}
            >
              Wszystkie punkty
            </button>
          </div>
          <Map
            places={
              category ? filteredAggregatedPlaces[category] : places.nodes
            }
            clickedElement={clickedElement}
          />
          <h2>Indeks miejsc</h2>
          <div css={indexContainerStyle}>
            {Object.keys(aggregatedPlaces).map((category) => (
              <div key={category}>
                <h3 css={listHeader}>{category}</h3>
                <ul css={indexListStyle}>
                  {aggregatedPlaces[category].map((place, index) => (
                    <li key={index}>
                      <button
                        css={indexListButtonStyle}
                        onClick={() => {
                          setClickedElement(place.id);
                          setCategory(null);
                        }}
                      >
                        {place.frontmatter.title}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </article>
      </TextWrapper>
    </ArticleComponent>
  );
};

export default Mapa;
